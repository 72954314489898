.container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.container > p {
  font-size: 1rem;
}

.container > em {
  font-size: .8rem;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

.dropzone-custom {
  position: relative;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 10px;
  border-radius: 8px;
  border: 2px dashed #57aba9;
  transition: border .24s ease-in-out;
  text-align: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.dropzone-custom::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(90deg, rgb(108, 169, 168) 0%, rgb(195, 233, 233) 100%);
  opacity: 0.3;
  z-index: -2;
  border-radius: 8px;
}

.dropzone-custom.dropzone-secondary::before {
  background-image: linear-gradient(90deg, #4050b5 0%, #e8eaf6 100%);
}

.dropzone-custom.dropzone-secondary {
  border: 2px dashed #3f51b5;
}

.dropzone-custom.dropzone-secondary .dropzone-icon {
  background: #cacee5;
}  

.dropzone-custom::after {
  content: '';
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  pointer-events: none;
}

.dropzone-custom.product-image-bg::after {
  background-image: url('./images/upload-image-bg.png');
}

.dropzone-custom.fill-product-by-image-bg::after {
  background-image: url('./images/upload-image-bg.png');
}

.dropzone-custom .dropzone-text {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px;
  font-size: 14px;
  pointer-events: none;
}
.dropzone-custom .dropzone-text.dropzone-loading {
  z-index: 10;
  pointer-events: auto;
  cursor: not-allowed;
}

.dropzone-custom .dropzone-icon {
  background: #C7E0E0;
  border-radius: 100%;
  padding: 10px;
}

.dropzone-custom .dropzone-icon img {
  display: block;
  width: 40px;
  height: 40px;
}

.dropzone-custom .list-items {
  display: inline-flex;
  max-width: 100%;
  font-weight: 400;
  pointer-events: none;
}

.dropzone-custom .list-items div {
  pointer-events: auto;
}

.dropzone-custom .list-items:last-child::after {
  content: "";
  padding-right: 140px;
  pointer-events: none; 
}

.dropzone-custom .loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.dropzone-custom.has-item .loader {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 8px;
  overflow: hidden;
}